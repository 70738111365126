// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "ifedmypet-amplify-20190301223416-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d1hnkgyp66y8e5.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:bd9f22bd-9275-48a2-bcfb-ded0ee81b7a7",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_MtHDWA33X",
    "aws_user_pools_web_client_id": "4pvvs2ofssubuv9gmj3mk5u4pm",
    "aws_mobile_analytics_app_id": "ed47265a150947babd960562e3271994",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
