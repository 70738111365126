import React from 'react';
import GlobalStyle from '../styles/global';
import SEO from './SEO';
import 'typeface-cantata-one';
import 'typeface-open-sans';

const Layout = () => (
  <>
    <SEO />
    <GlobalStyle />
  </>
);

export default Layout;
